<template>
  <div class="row">
    <div class="col-12">
      <!-- CONTENT GOES HERE -->
      <card class="mb-2">
        <div class="row align-items-center justify-content-between">
          <div class="col-8 col-lg-11">
            <h5 class="h3">{{getTrans('messages.camhead')}}</h5>
          </div>
          <div class="col-4 col-lg-1">
            <el-button type="primary" size="small" @click="tools"
              >{{getTrans('messages.back')}}</el-button
            >
          </div>
        </div>
        <div class="container-fluid">
          <div class="row block align-items-center">
            <div class="col-12">
              <span class="title">{{getTrans('messages.script_tag_header')}}</span>
              <span>{{getTrans('messages.camhead_script')}}</span>
              <div class="form-group mt-2">
                <form>
                  <label>{{getTrans('messages.integration_script')}}</label>
                  <div class="alert alert-info">
                    <!-- eslint-disable -->
                    <code class="code-block text-white"
                      >&lt;script type="text/javascript"&gt; <br />var _diva =
                      _diva || [];
                      <br />
                      _diva['setAccount'] = 66;<br />
                      <span v-if="datavalues.gender"
                        >_diva['gender'] = "{{ datavalues.gender }}"<br
                      /></span>
                      _diva['CamHead'] = [];<br />
                      _diva['CamHead']['options'] = [];<br />
                      <span v-if="datavalues.camsites"
                        >_diva['CamHead']['options']['platforms'] = [
                        {{ datavalues.camsites }} ]<br
                      /></span>
                      <span v-if="datavalues.profiles.length"
                        >_diva['CamHead']['options']['platforms'] = [
                        {{ datavalues.profiles }} ]<br
                      /></span>
                      <span
                        v-for="(value,
                        propertyname) in datavalues.customTracking"
                        :key="propertyname"
                      >
                        _diva['CamHead']['options']['{{ propertyname }}'] = "{{
                          value
                        }}";
                        <br />
                      </span>
                      <span
                        v-if="
                          datavalues.settings && datavalues.settings.delay > 0
                        "
                        >_diva['CamHead']['options']['delay'] =
                        {{ datavalues.settings.delay }};
                        <br />
                      </span>
                      <span
                        v-if="datavalues.settings && datavalues.settings.ding"
                        >_diva['CamHead']['options']['ding'] = "{{
                          datavalues.settings.ding
                        }}";
                        <br />
                      </span>
                      >&lt;/script&gt;<br />
                      &lt;script id="diva_services"
                      src="//diva.services/services"
                      type="text/javascript"&gt;&lt;/script&gt;</code
                    >
                    <!-- eslint-enable -->
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-12">
              <span class="title">{{getTrans('messages.camhead_settings')}}</span>
              <ToolOptionsSelector
                @updated="updated"
                :showModules="[
                  'camsites',
                  'profiles',
                  'tracking',
                  'settings',
                  'gender'
                ]"
              ></ToolOptionsSelector>
            </div>
          </div>
        </div>
      </card>
      <!-- END OF CONTENT -->
    </div>
  </div>
</template>
<script>
// Components
import ToolOptionsSelector from "./components/ToolOptionsSelector";
import {
  ElButton,
  ElRadio,
  ElRadioButton,
  ElButtonGroup,
  ElCollapse,
  ElCollapseItem,
  ElInput,
  ElSelect,
  ElOption
} from "element-plus";
export default {
  name: "camhead",
  components: {
    ToolOptionsSelector,
    [ElButton.name]: ElButton,
    [ElInput.name]: ElInput,
    [ElOption.name]: ElOption,
    [ElSelect.name]: ElSelect,
    [ElRadio.name]: ElRadio,
    [ElButtonGroup.name]: ElButtonGroup,
    [ElRadioButton.name]: ElRadioButton,
    [ElCollapse.name]: ElCollapse,
    [ElCollapseItem.name]: ElCollapseItem
  },
  data() {
    return {
      value: true,
      loading: false,
      datavalues: { profiles: [] }
    };
  },
  methods: {
    tools() {
      this.$router.push({ name: "Tools" });
    },
    updated(options) {
      this.selectedCamsitesUpdated(options.camsites);
      this.selectedProfilesUpdated(options.profiles);
      this.datavalues.customTracking = options.tracking;
      this.datavalues.settings = options.settings;
      this.datavalues.gender = options.gender;
      this.$forceUpdate();
    },
    selectedCamsitesUpdated(camsites) {
      if (Array.isArray(camsites)) {
        var selected =
          '"' +
          camsites
            .map(c => {
              return c.title;
            })
            .join('","') +
          '"';
        if (selected == '""') {
          selected = "";
        }
        this.datavalues.camsites = selected;
      } else {
        this.datavalues.camsites = "";
      }
    },
    selectedProfilesUpdated(profiles) {
      if (profiles.length) {
        this.datavalues.profiles =
          '"' +
          profiles
            .map(p => {
              return p.id;
            })
            .join('","') +
          '"';
      } else {
        this.datavalues.profiles = "";
      }
    }
  },
  computed: {}
};
</script>
<style></style>
